
  /* src/components/Hero.css */
.hero-container {
  margin: 20px; /* Adjust the margin as needed */
}

.slick-slider {
  width: 100%;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%; /* Set to 100% for responsiveness */
  height: 700px; /* Set the desired fixed height */
  object-fit: cover; /* Ensure the image covers the entire container */
}